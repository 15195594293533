ngb-tooltip-window.small .tooltip-inner {
    font-size: smaller;
}

.tooltip-danger {
    z-index: 10000;
    .tooltip-inner {
        background-color: theme-color('danger');
        // font-size: 125%;
    }
}
.tooltip-danger .arrow::before {
    border-top-color: theme-color('danger');
}
