body .p-component,
.p-inputtext {
    font-family: $font-family-sans-serif;
    font-size: 14px;
}

body .p-datatable .p-datatable-tbody > tr > td,
body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-datatable-tfoot > tr > th,
body .p-datatable .p-datatable-tfoot > tr > td,
body .p-datatable .p-datatable-footer > tr > th,
body .p-datatable .p-datatable-footer > tr > td,
body .p-paginator,
body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-summary body .p-datatable .p-datatable-tfoot,
body .p-datatable .p-datatable-footer {
    border-color: #dfdfdf !important;
}

body .p-datatable-table .p-datatable-thead > tr > th,
body .p-datatable-table .p-datatable-thead > tr > td,
body .p-datatable .p-datatable-tfoot > tr > th,
body .p-datatable .p-datatable-tfoot > tr > td,
body .p-datatable .p-datatable-tbody > tr:nth-child(even),
body .p-paginator,
body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary {
    background-color: #f8f9fa !important;
}

body .p-datatable .p-sortable-column.p-state-highlight,
body .p-datatable .p-sortable-column.p-state-highlight .p-sortable-column-icon {
    color: #333333;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #007ad9 !important;
    color: #ffffff;
}
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover {
    color: #333333;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #848484;
}

body .p-inputgroup p-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-component:disabled {
    opacity: 1;
    background-color: #f8f9fa !important;
}

.mailing-rules-menu {
    .p-menuitem-link {
        flex-direction: row-reverse;
        justify-content: space-between;

        .p-menuitem-icon {
            color: crimson;
        }
    }
}

.p-datepicker table th {
    text-align: center;
}

.p-button {
    background: #0C2556;
    border-color: #0C2556;

    &:enabled:hover {
        background: #0C2556;
        border-color: #0C2556;
    }

    &:enabled:focus {
        box-shadow: 0 0 0 0.2rem #0C2556;
    }
}

.p-dropdown-items {
    margin-bottom: 0;
}

.p-autocomplete-items {
    margin-bottom: 0;

    .p-autocomplete-empty-message {
        padding: 0.429rem 0.857rem;
    }
}

.p-dropdown-panel {
    .p-dropdown-items {
        p-dropdownitem {
            &:first-of-type {
                .p-dropdown-item {
                    &:not(:hover):not(.p-highlight).p-focus {
                        background: none;
                        color: unset;
                    }
                }
            }
        }
    }
}

.p-menu-list {
    .p-menuitem {
        &:first-child {
            .p-menuitem-content {
                &:not(:hover) {
                    background: none;
                    color: unset;
                }
            }
        }
    }
}

.p-multiselect-panel {
    p-multiselectitem {
        .p-multiselect-item {
            &.p-focus {
                background: unset;

                &.p-highlight {
                    background: #007ad9;
                }

                &:not(.p-highlight) {
                    background: unset;
                    &:hover {
                        background: #eaeaea;
                    }
                }
            }
        }
    }
}
