.bs-popover-left.popover-danger > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
    border-left-color: theme-color('danger');
}

.bs-popover-left.popover-danger > .arrow::after,
.bs-popover-auto[x-placement^='left'].popover-danger > .arrow::after {
    border-left-color: theme-color('danger');
}

.popover-danger {
    border-color: theme-color('danger');

    .popover-header {
        background-color: theme-color('danger');
        color: theme-color('light');
    }
}
