.module-menu {
    .card {
        fa-icon {
            filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.25));
        }
        cursor: pointer;
        transition: all 300ms ease-in-out;
    }

    .card:hover:not(.disabled) {
        background-color: darken(map-get($theme-colors, 'light'), 5%);
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .card.disabled {
        color: lighten(gray, 25%) !important;
        cursor: not-allowed;
        fa-icon {
            color: lighten(gray, 25%) !important;
        }
    }
}
