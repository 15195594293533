/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

@import 'primeicons/primeicons';
@import 'primeng/resources/themes/nova/theme';
@import 'primeng/resources/primeng.min';
@import 'scss/settings';
@import 'bootstrap/scss/bootstrap';

@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-toastr/toastr';
@import 'jsoneditor/dist/jsoneditor.min';

@import 'scss/modals';
@import 'scss/popovers';
@import 'scss/tooltip';
@import 'scss/primeNgLite';
@import 'scss/flex';
@import 'scss/alert';
@import 'scss/tabNav';
@import 'scss/ngselect';
@import 'scss/indexPage';
@import 'scss/links';
@import 'scss/forms';
@import 'scss/badges';

$theme-success-color: theme-color('success') !default;

html,
body {
    height: 100%;
    min-height: 100%;
    font-size: 0.95rem;
}

body .ui-widget {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    text-decoration: none;
}

#toast-container {
    margin-top: 41px;
}

a {
    color: theme-color('secondary');
}

.lead {
    font-weight: 400;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.sidebar-menu-popover {
    font-size: 1em;

    .nav-link {
        color: #333;
        font-weight: 400;
    }

    .nav-link.active {
        color: #007bff;
    }

    .nav-link:hover,
    .nav-link.active {
        color: theme-color('primary');
    }

    .popover-body {
        padding: 0; //0.25rem 0.5rem;
    }
}

.shadow-sm-invert {
    box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.strong {
    font-weight: bold;
}

.qw-form {
    label:not(.form-check-label):not(.btn):not(.custom-control-label) {
        font-weight: 500;
        font-size: 0.85rem;
        margin-bottom: 0;
        color: #6c757d;
    }

    .form-control {
        background-color: #f8f9fa;
        max-width: 250px;
    }
    .form-control:hover {
        background-color: darken(#f8f9fa, 2.5%);
    }

    .form-control:focus {
        background-color: #fff;
        border: 1px solid theme-color('primary');
        outline: 0;
        box-shadow: 0 0 0 1px theme-color('primary');
    }
}

.qw-form-light {
    label:not(.form-check-label):not(.btn):not(.custom-control-label) {
        font-weight: 500;
        font-size: 0.85rem;
        margin-bottom: 0;
        color: #6c757d;
    }
}

.table.table-borderless {
    thead {
        tr {
            color: #6c757d;
            border-bottom-width: 2px !important;
            font-size: 0.85rem;

            th {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
        }
    }

    th:first-child {
        padding-left: 0;
    }

    td:first-child {
        padding-left: 0.1rem;
    }

    th:last-child,
    td:last-child {
        padding-right: 0;
    }
}

.qw-sidebar {
    padding: 0 !important;
    background: #fff;

    .btn.close-button {
        position: fixed;
        top: 16px;
        left: -17px;
        width: 32px;
        height: 32px;
        border-color: #fff;
        border-width: 2px;
    }
}

.qw-skeleton {
    min-height: 1em;
    min-width: 1em;
    background-color: #fcfcfd;
    border-radius: 5px;

    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: inline-block;
    animation: progress 1.5s ease-in-out infinite;
    background: rgb(239, 241, 246) no-repeat;
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0)
    );
    background-size: 60px 100%;

    &:after,
    &:before {
        box-sizing: border-box;
    }
}

@keyframes progress {
    0% {
        background-position: -50px 0;
    }
    100% {
        background-position: calc(50px + 100%) 0;
    }
}

.ui-autocomplete-panel .ui-autocomplete-list {
    padding: 0;
}

.ui-autocomplete-panel .ui-autocomplete-list-item {
    padding: 0;
    margin: 0;
}

.autocomplete-list-location {
    white-space: nowrap;

    em {
        font-style: inherit !important;
        font-weight: bold !important;
    }
}

.text-underline-dashed {
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: help;
}

.text-strikethrough {
    text-decoration: line-through;
}

.sneaky-link {
    text-decoration: underline;
    text-decoration-style: dotted;
    color: theme-color('dark') !important;

    &:hover {
        color: theme-color('primary') !important;
    }
}

.qwyk-payment-providers-modal {
    .modal-content {
        border: none;
        background-color: transparent !important;
        box-shadow: none !important;
    }
}

.visually-hidden{
    display: none;
}

ngb-modal-backdrop {
    z-index: 1050 !important;
}


