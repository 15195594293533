.ng-select {
    &.ng-select-disabled .ng-select-container {
        background-color: #f8f9fa !important;
    }
    &.custom.custom-sm {
        .ng-select-container {
            min-height: 10px;
            @extend .custom-select-sm !optional;
            border-radius: 0.2em;
            .ng-value-container {
                padding-left: 0;
            }
        }

        ng-dropdown-panel {
            font-size: small;
        }
    }
    .ng-placeholder {
        margin: 0.25rem 0.75rem;
    }
}

.form-control:disabled,
.form-control[readonly],
.p-inputtext[readonly] {
    background-color: #f8f9fa !important;
}

arv-validation-messages .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: red;
}
