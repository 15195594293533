ng-select.is-invalid .ng-select-container {
    border-color: #dc3545;
}

ng-select.ng-select-sm .ng-input {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.15em;
}

.ng-select.ng-select-disabled .ng-select-container {
    background-color: #f8f9fa !important;
}
