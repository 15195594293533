$theme-colors: (
        'primary': #0C2556,
        'secondary': #2A6ECB,
        'dark': #1D1F1F,
        'warning': #ff7900,
        'light': #F9F9F9
);

$font-family-sans-serif: 'Roboto',
  // Basic web fallback
'Helvetica Neue',
Arial, sans-serif,
  // Emoji fonts
'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$font-family-headers: 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$body-color: #1D1F1F;

$font-weight-normal: 400;
$font-weight-bold: 700;

$headings-font-weight: 800;

$border-radius: 0.2em;
$border-radius-sm: 0.15em;
